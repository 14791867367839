.hero {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    height: 84vh;
    border-bottom: 96px solid transparent;
    border-left: 100vw solid #095d7e;

    z-index: -1;
  }
  .container {
    height: 100%;
    .row {
      width: 100%;
      height: 100%;
      justify-content: center;
      text-align: center;
      .info {
        margin-top: 123px;
        max-width: 764px;
        width: 100%;
        padding: 40px 0;
        color: #fff;
        display: flex;
        flex-direction: column;
        gap: 40px;
        .title {
          font-size: 60px;
          font-weight: 700;
          line-height: 75px;
          letter-spacing: -0.01em;
          color: inherit;
        }
        .detail {
          font-size: 20px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: -0.01em;
          color: inherit;
        }
        .btn {
          width: 100%;
        }
      }
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1000px) {
  .hero {
    .container {
      .row {
        .info {
          margin-top: 200px;
          max-width: 504px;
          padding: 25px 0;
          gap: 25px;
          align-items: center;
          .title {
            font-size: 40px;
            line-height: 50px;
          }
          .detail {
            font-size: 17px;
            line-height: 24px;
          }
          .btn {
            max-width: 250px;
          }
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .hero {
    height: 80vh;
    &::after {
      height: 66vh;
    }
    .container {
      .row {
        .info {
          margin-top: 200px;
          max-width: 304px;
          padding: 0;
          gap: 10px;
          .title {
            font-size: 20px;
            line-height: 28px;
          }
          .detail {
            font-size: 14px;
            line-height: 20px;
          }
          .btn {
            max-width: 150px;
          }
        }
      }
    }
  }
}

.form-page {
  min-height: calc(100vh - 200px);
  .container {
    min-height: calc(100vh - 200px);
    .row {
      width: 100%;
      min-height: calc(100vh - 200px);
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;
      .left-side {
        width: 100%;
        display: flex;
        justify-content: center;
        .logo-box {
          aspect-ratio: 2/1;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
      }
      .right-side {
        padding: 50px;
        border: 1px solid #6666664d;
        border-radius: 32px;
        text-align: center;
        max-width: 654px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 7px;
        .info-group {
          max-width: 460px;
          width: 100%;
          text-align: center;
          .caption {
            font-size: 30px;
            font-weight: 500;
            line-height: 40px;
            letter-spacing: -0.01em;
            color: #333333;
          }
          .sub-title {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            color: #666666;

            text-align: center;
          }
        }
        .form-group {
          width: 100%;
          .top {
            display: flex;
            justify-content: space-between;
            text-align: center;
            margin-bottom: 12px;
            .label {
              margin-bottom: 0;
            }
            .icon {
              border: none;
              outline: none;
              cursor: pointer;
              background-color: transparent;
              width: 24px;
              height: 24px;
              img {
                display: flex;
              }
            }
            .hide-area {
              cursor: pointer;
              display: flex;
            }
          }
          .label {
            font-size: 16px;
            font-weight: 400;
            line-height: 19.36px;
            color: #666666;
            margin-bottom: 12px;
            display: block;
            width: 100%;
            text-align: left;
            &.error {
              color: #ff3a2f;
            }
          }
          .inp {
            border: 1px solid #666666;
            outline: none;
            width: 100%;
            padding: 15px 24px;
            border-radius: 12px;
            &:focus {
              border: 1px solid #095d7e;
            }
            &.error {
              border: 1px solid #ff3a2f;
            }
          }
          .error-component {
            margin-top: 10px;
            background: #ff3a2f26;
            border-radius: 12px;
            padding: 17px;
            display: flex;
            justify-content: center;
            text-align: center;

            .info {
              display: flex;
              justify-content: center;
              align-items: center;
              max-width: 270px;
              gap: 15px;
              .icon {
                width: 20px;
                height: 20px;
                img {
                  width: 20px;
                  height: 20px;
                }
              }
              .text {
                color: #ff3a2f;
                font-weight: 400;
                font-size: 16px;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: center;
              }
            }
          }
        }
        .forget-password {
          width: 100%;
          font-size: 18px;
          font-weight: 400;
          line-height: 21.78px;
          text-align: right;
          text-decoration: underline;
          color: #666666cc;
        }
        .alert-area {
          width: 100%;
          .alert-group {
            display: flex;
            justify-content: space-between;
            gap: 5px;
            width: 100%;
            .detail {
              width: 50%;
              font-size: 14px;
              font-weight: 400;
              line-height: 16.94px;
              color: #66666699;
              text-align: left;
              padding: 8px 0 8px 16px;
              display: inline-block;
              position: relative;
              &::before {
                position: absolute;
                content: "";
                top: 12px;
                left: 0;
                width: 8px;
                height: 8px;
                background: #66666699;
                border-radius: 100%;
              }
            }
          }
        }
        .submit {
          background: #14967f;
          border-radius: 28px;
          text-align: center;
          width: 100%;
          border: none;
          outline: none;
          cursor: pointer;
          color: #ffffff;
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: -0.01em;
          padding: 15px;
          img {
            display: none;
          }
          &.specific {
            display: flex;
            gap: 5px;
            justify-content: center;
            align-items: center;
            img {
              display: inline-block;
              width: 22px;
              height: 22px;
            }
          }
        }
        .login-adviser {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.01em;
          text-align: center;
          color: #333333;
          a {
            color: #61bc84;
            font-weight: 600;
          }
        }
        .alert-title {
          font-size: 30px;
          font-weight: 500;
          line-height: 40px;
          letter-spacing: -0.01em;
          color: #333333;
        }
        .alert-sub-title {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.01em;
          text-align: center;
          color: #666666;
        }
        .related-website {
          width: 100%;
          display: flex;
          margin-top: 7px;
          display: flex;
          gap: 10px;
          a {
            color: #333333;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: -0.01em;
            padding: 10px 8px;
            background: #e6eff2;
            border-radius: 6px;
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .form-page {
    .container {
      .row {
        margin-bottom: 30px;

        .right-side {
          padding: 30px;
          .info-group {
            .caption {
              font-size: 25px;
            }
            .sub-title {
              font-size: 14px;
            }
          }
          .form-group {
            .inp {
              padding: 13px 16px;
            }
          }
          .forget-password {
            font-size: 16px;
          }

          .alert-title {
            font-size: 25px;
          }
          .alert-sub-title {
            font-size: 14px;
          }
          .related-website {
            a {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .form-page {
    .container {
      .row {
        margin-bottom: 20px;
        justify-content: center;
        .left-side {
          display: none;
        }
        .right-side {
          padding: 30px;
          max-width: 500px;
          .info-group {
            .caption {
              font-size: 25px;
            }
          }

          .alert-title {
            font-size: 25px;
          }
        }
      }
    }
  }
}
@media (max-width: 428px) {
  .form-page {
    min-height: 100%;
    .container {
      min-height: 100%;
      .row {
        margin-bottom: 20px;
        min-height: 100%;
        .right-side {
          padding: 0px;
          max-width: 500px;
          border: none;
          .info-group {
            .caption {
              font-size: 20px;
            }
          }
          .forget-password {
            font-size: 14px;
          }
          .form-group {
            .error-component {
              padding: 10px;
              .info {
                max-width: 100%;
                gap: 10px;

                .text {
                  font-size: 12px;
                }
              }
            }
          }
          .alert-area {
            width: 100%;
            .alert-group {
              flex-direction: column;
              .detail {
                width: 100%;
              }
            }
          }

          .alert-title {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.home-caption {
  display: flex;
  flex-direction: column;
  gap: 35px;
  .line {
    display: inline-block;
    width: 94px;
    height: 7px;
    background-color: #064259;
  }
  .title {
    font-size: 36px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: -0.01em;
    color: #333333;
  }
  .detail {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #545454;
    width: 100%;
  }
  .button {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #064259;
    cursor: pointer;
    padding: 2px 2px 2px 0;
  }
}

@media (max-width: 1000px) {
  .home-caption {
    gap: 25px;
    .line {
      display: inline-block;
      width: 77px;
      height: 5px;
      background-color: #064259;
    }
    .title {
      font-size: 31px;
      line-height: 32px;
    }
    .detail {
      font-size: 12px;
      line-height: 18px;
    }
    .button {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
@media (max-width: 575px) {
  .home-caption {
    gap: 15px;
    .line {
      width: 60px;
    }
    .title {
      font-size: 24px;
    }
  }
}

.general-info {
  width: 100%;
  height: 100%;
  .row {
    min-height: 489px;
    justify-content: space-between;
    .left-side {
      width: 100%;
      max-width: 521px;
      align-self: center;
      .title {
        font-weight: 700;
        color: #333333;
        margin-bottom: 20px;
      }
      .subtitle {
        font-weight: 500;
        margin-bottom: 45px;
      }
      .list {
        .item {
          padding: 0 0 5px 32px;
          position: relative;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.01em;

          &::after {
            content: "";
            display: inline-block;
            width: 24px;
            height: 24px;
            background-image: url(../../../img/Icons/tick.svg);
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
    .right-side {
      max-width: 632px;
      min-width: 450px;

      width: 100%;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
      margin-top: 13px;
      align-self: self-end;
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1000px) {
  .general-info {
    .row {
      .left-side {
        max-width: 521px;
        width: 100%;
        align-self: center;
        .title {
          margin-bottom: 13px;
        }
        .subtitle {
          font-size: 14px;
          margin-bottom: 33px;
        }
        .list {
          .item {
            padding: 0 0 5px 30px;
            font-size: 14px;
          }
        }
      }
      .right-side {
        max-width: 632px;
        min-width: 380px;
        width: 100%;
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
        margin-top: 13px;
        align-self: self-end;
      }
    }
  }
}
@media (max-width: 780px) {
  .general-info {
    .row {
      min-height: max-content;
      flex-direction: column;
      justify-content: flex-end;
      gap: 25px;
      .right-side {
        width: 100%;
        min-width: 100%;
      }
      .left-side{
        padding-top: 30px;
      }
    }
  }
}
@media (max-width: 575px) {
  .general-info {
    margin-top: 0;
    padding: 15px 0 70px;
    .row {
      min-height: none;
      .left-side {
        padding-top: 0;
        .title {
          margin-bottom: 5px;
        }
        .subtitle {
          font-size: 12px;
          margin-bottom: 20px;
        }
        .list {
          .item {
            padding: 0 0 5px 27px;
            font-size: 12px;
          }
        }
      }
      .right-side {
        margin-top: 0px;
      }
    }
  }
}

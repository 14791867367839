.select-symptom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  .left-side {
    .error {
      color: #b00020;
      margin-top: 7px;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: -1%;
    }
  }
  .right-side {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 21px 0;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    &.active {
      background-color: rgba(51, 51, 51, 0.258);
      z-index: 1;
    }
  }
}
@media (max-width: 750px) {
  .select-symptom {
    flex-direction: column;
    gap: 15px;
    .right-side {
      justify-content: center;
      padding: 21px 0;
    }
  }
}
@media (max-width: 575px) {
  .select-symptom {
    .right-side {
      padding: 21px 0 80px;
      .body-mobile {
        .render-body,
        .bg-body {
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

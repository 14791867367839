.home-card {
  width: 100%;
  padding: 35px 40px;
  box-shadow: 0px 13px 19px 0px #00000012;
  background-color: #fff;
  .top {
    width: 70px;
    height: 75px;
    overflow: hidden;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .bottom {
    .title {
      font-weight: 700;
      color: #333333;
      margin-bottom: 20px;
    }
    .line {
      display: inline-block;
      max-width: 50px;
      width: 100%;
      height: 2px;
      background-color: #064259;
      margin-bottom: 20px;
    }
    .text {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #545454;
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1000px) {
  .home-card {
    padding: 25px 30px;
    max-width: none;
    .top {
      width: 55px;
      height: 60px;
      margin-bottom: 15px;
    }
    .bottom {
      .title {
        font-size: 14px;
        margin-bottom: 15px;
      }
      .line {
        max-width: 41px;
        margin-bottom: 15px;
      }
      .text {
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 575px) {
  .home-card {
    padding: 20px;
    .top {
      width: 44px;
      height: 48px;
      margin-bottom: 10px;
    }
    .bottom {
      .title {
        margin-bottom: 10px;
      }
      .line {
        margin-bottom: 10px;
      }
    }
  }
}

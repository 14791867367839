.account-password {
  width: calc(100% - 300px);
  .user-name {
    padding: 20px 0;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: #0f172a;
  }
  .personality-information {
    width: 100%;
    margin-top: 24px;
    padding: 20px 24px;
    box-shadow: 0px 0px 4px -2px #0f172a82;
    border-radius: 16px;
    box-shadow: 0px 0px 9.9px -1px #0f172a1a;
    background: #ffffff;
    .form-group {
      margin-bottom: 32px;
      .label {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #0f172a;
        margin-bottom: 4px;
        display: block;
        &.error {
          color: #ff3a2f;
        }
      }
      .inp {
        padding: 8px 12px;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #333333;
        border: none;
        outline: none;
        border-bottom: 1px solid #e2e8f0;
        width: 100%;
        &.error {
          border-bottom: 1px solid #ff3a2f;
        }
      }
      &:first-child {
        margin-top: 15px;
      }
    }
    .button-area {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      .error-alert {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -1%;
        color: #ff3a2f;
        &.succes {
          color: #00b074;
        }
      }
      button {
        padding: 7px 16px;
        background: #14967f;
        box-shadow: 0px 1px 2px 0px #0f172a0f;
        cursor: pointer;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #ffffff;
      }
    }
  }
}
@media (max-width: 1024px) {
  .account-password {
    width: calc(100% - 250px);
    .user-name {
      padding: 15px 0;
      font-size: 25px;
    }
    .personality-information {
      margin-top: 20px;
      padding: 18px 20px;
      .form-group {
        margin-bottom: 26px;
        .label {
          font-size: 16px;
        }
        .inp {
          font-size: 16px;
        }
        &:first-child {
          margin-top: 10px;
        }
      }
      // .button-area {
      //   display: flex;
      //   justify-content: flex-end;
      //   button {
      //     padding: 7px 16px;
      //     background: #14967f;
      //     box-shadow: 0px 1px 2px 0px #0f172a0f;
      //     cursor: pointer;
      //     border: none;
      //     outline: none;
      //     cursor: pointer;
      //     border-radius: 8px;
      //     font-size: 16px;
      //     font-weight: 500;
      //     line-height: 22px;
      //     letter-spacing: -0.01em;
      //     color: #ffffff;
      //   }
      // }
    }
  }
}
@media (max-width: 768px) {
  .account-password {
    width: 100%;
    .user-name {
      display: none;
    }
    .personality-information {
      margin-top: 0px;
      padding: 0px;
      border: none;
      box-shadow: 0px 0px 9.9px -1px transparent;
      .form-group {
        margin-bottom: 15px;
      }
      .button-area {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        button {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}

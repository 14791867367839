.pain-level {
  width: 100%;
  min-height: 430px;
  padding-top: 30px;
  .row {
    .degree {
      // max-width: 105px;
      width: 100%;
      border: 4px solid transparent;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        border-color: #095d7e;
        padding: 2px;
        margin-top: -4px;
      }
      &.active {
        .top {
          .angle {
            opacity: 1;
            visibility: visible;
          }
          .number {
            max-width: 105px;
            width: 100%;
            color: #fff;
            background: #095d7e;
            border-radius: 10px;
          }
        }
      }
      .top {
        text-align: center;
        position: relative;
        .angle {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          bottom: -40px;
          opacity: 0;
          visibility: hidden;
          .triangle {
            display: inline-block;
            width: 15px;
            height: 15px;
            background: #095d7e;
            transform: rotate(45deg);
          }
        }
        .number {
          display: inline-block;
          font-size: 18px;
          font-weight: 700;
          line-height: 25px;
          letter-spacing: -0.01em;
          padding: 13px 0;
          color: #333333;
        }
      }
      .bottom {
        margin-top: 9px;
        width: 100%;
        height: 50px;
        background: #e8e8e8;
      }
      &:first-child {
        .bottom {
          border-top-left-radius: 7px;
          border-bottom-left-radius: 7px;
        }
      }
      &:last-child {
        .bottom {
          border-top-right-radius: 7px;
          border-bottom-right-radius: 7px;
        }
      }
    }
  }
  .btn-group {
    display: none;
    margin-top: 10px;
    width: 100%;
    justify-content: center;
    gap: 30px;
    .decrement,
    .increment {
      cursor: pointer;
      border: none;
      background-color: transparent;
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
@media (max-width: 700px) {
  .pain-level {
    min-height: 350px;
    .row {
      .degree {
        border: 1px solid transparent;
        &.active {
          border-color: transparent;
          .top {
            .number {
              border-radius: 6px;
            }
          }
        }
        .top {
          .angle {
            bottom: -44px;

            .triangle {
              width: 10px;
              height: 10px;
            }
          }
          .number {
            font-size: 16px;
          }
        }
      }
    }
    .btn-group {
      display: flex;
    }
  }
}
@media (max-width: 575px) {
  .pain-level {
    padding-top: 15px;
    .row {
      .degree {
        .top {
          .angle {
            bottom: -46px;
            .triangle {
              width: 8px;
              height: 8px;
            }
          }
          .number {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.website-description {
  width: 100%;
  padding: 120px 0;
  background-color: #e6eff2;
  .container {
    .row {
      align-items: center;
      justify-content: space-between;
      gap: 32px;
      .home-caption {
        .detail {
          max-width: 418px;
        }
      }
      .video-area {
        position: relative;
        max-width: 632px;
        width: 100%;
        border-radius: 65px;
        overflow: hidden;
        cursor: pointer;
        .bg-img {
          width: 100%;
          height: 440px;
          //   test
          background: #939393;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .play-icon {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .video-modal {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    background-color: #00000025;
    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1000px) {
  .website-description {
    padding: 60px 0;
    .container {
      .row {
        gap: 16px;
        .video-area {
          max-width: 500px;
          border-radius: 40px;
          .bg-img {
            height: 350px;
          }
          .play-icon {
            img {
              width: 90px;
              height: 90px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .website-description {
    padding: 15px 0;
    .container {
      .row {
        gap: 47px;
        flex-direction: column;
        .video-area {
          max-width: 335px;
          border-radius: 30px;
          .bg-img {
            height: 200px;
          }
          .play-icon {
            img {
              width: 66px;
              height: 66px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

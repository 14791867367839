.yes-no-question {
  min-height: 430px;
  padding-top: 30px;
  .row {
    justify-content: space-between;
    .card {
      width: 31.4%;
      height: 225px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      border-radius: 10px;
      cursor: pointer;
      &.active {
        border: 3px solid #b3cdd7;
      }
      &:hover {
        background: #095d7e;
        .icon {
          svg {
            path {
              stroke: #fff;
            }
          }
        }
        .caption {
          color: #fff;
        }
      }
      .icon {
        width: 100px;
        height: 100px;
        margin: 0 auto;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .caption {
        color: #333333;
        text-transform: capitalize;
      }
    }
  }
}
@media (max-width: 1000px) {
  .yes-no-question {
    min-height: 100%;
    padding: 30px 0;
    .row {
      .card {
        height: 170px;
        .icon {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .yes-no-question {
padding: 20px 0 81px;
    .row {
      gap: 20px;
      .card {
        .icon {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .yes-no-question {
    .row {
      flex-direction: column;
      gap: 20px;
      .card {
        width: 90%;
        margin: 0 auto;
        height: 128px;
      }
    }
  }
}

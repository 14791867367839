.region {
  width: 100%;
  .map {
    padding: 30px 0;
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    svg {
      width: 100%;
      height: 100%;
      g {
        cursor: pointer;
        path {
          transition: 0.4s ease;
        }
        &:hover {
          path {
            fill: #5a92a9;
          }
        }
        &.active {
          path {
            fill: #5a92a9;
          }
        }
      }
    }
  }
  .error {
    .text {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.01em;
      color: #b00020;
      margin-bottom: 30px ;
    }
  }
}

@media (max-width: 100px) {
  .region {
    .map {
      padding: 20px 0;
      svg {
        min-height: max-content;
      }
    }
    .error {
      .text {
        margin: 20px 0;
      }
    }
  }
}
@media (max-width: 575px) {
  .region {
    padding: 15px 0 81px;
    .map {
      padding: 15px 0 0 0;
    }
    .error {
      .text {
        margin: 10px 0;
      }
    }
  }
}

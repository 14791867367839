.static-dynamic-symptom {
  width: 100%;
  padding-bottom: 94px;
  min-height: 430px;
  &.question {
    .symptom-form {
      .form-group {
        flex-direction: column;
        align-items: flex-start;
        .symptom{
          max-width: 100%;
        }
        .inp-area {
          width: 100%;

        }
      }
    }
  }

  .symptom-form {
    .form-group {
      width: 100%;
      border-bottom: 1px solid #b0b0b0;
      padding: 10px 27px;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      .symptom {
        max-width: 390px;
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: -0.01em;
        color: #333333;
      }
      .inp-area {
        display: flex;
        gap: 30px;
        width: 280px;
        .inp-group {
          display: flex;
          align-items: center;
          gap: 14px;
          .text {
            font-family: Inter;
            font-weight: 500;
            line-height: 25px;
            letter-spacing: -0.01em;
            color: #333333;
            white-space: nowrap;
            &.textError {
              color: #b00020;
            }
          }
        }
      }
    }
  }
}

// Responsive
@media (max-width: 1000px) {
  .static-dynamic-symptom {
    padding: 20px 0 50px;
    min-height: max-content;
    .symptom-form {
      .form-group {
        padding: 8px 21px;
        .symptom {
          font-size: 16px;
        }
        .inp-area {
          display: flex;
          gap: 25px;
          .inp-group {
            .text {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .static-dynamic-symptom {
    .symptom-form {
      .form-group {
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
      }
    }
  }
}
@media (max-width: 575px) {
  .static-dynamic-symptom {
    padding: 5px 0 81px;
    .alert {
      margin-top: none;
    }
    .symptom-form {
      .form-group {
        padding: 8px 15px;
        .symptom {
          font-size: 14px;
        }
        .inp-area {
          width: 100%;
          gap: 20px;
          .inp-group {
            .text {
              font-size: 12px;
              white-space: wrap;
            }
          }
        }
      }
    }
  }
}

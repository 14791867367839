/* --------------------------- Google Font Family --------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* --------------------------- Google Font Family --------------------------- */

/* ------------------------------- Reset Code ------------------------------- */
* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  transition: 0.5s ease;
}

h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.01em;
}
h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.01em;
}
h6 {
  font-size: 16px;
  line-height: 22px;
}
.container {
  padding: 0 72px;
}
.result-container {
  margin: 0 auto;
  width: 82.87%;
}
.scroll {
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #095d7e;
  }
  &::-webkit-scrollbar-track-piece:end {
    margin-bottom: 15px;
  }

  &::-webkit-scrollbar-track-piece:start {
    margin-top: 15px;
  }
}
.row {
  width: 100%;
  display: flex;
}
.region,
.static-dynamic-symptom,
.dynamic-symptom {
  .alert {
    margin-top: 10px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #545454;
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1000px) {
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  h6 {
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.01em;
  }
  .container {
    padding: 0 46px;
  }
  .result-container {
    width: 90%;
  }
  .region .static-dynamic-symptom,
  .dynamic-symptom {
    .alert {
      font-size: 14px;
    }
  }
}
@media (max-width: 575px) {
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  .container {
    padding: 0 20px;
  }
  .result-container {
    width: 100%;
    padding: 0 20px;
  }
  .region .static-dynamic-symptom,
  .dynamic-symptom {
    .alert {
      font-size: 12px;
    }
  }
}

.answer-info {
  .top {
    padding: 25px 0;
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 15px -3px #00000012;
    .result-container {
      .row {
        justify-content: space-between;
        align-items: center;
        .caption {
          font-size: 16px;
          color: #333333;
        }
        .show-button {
          padding: 5px 0 5px 5px;
          display: flex;
          gap: 15px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.01em;
          color: #095d7e;
          cursor: pointer;
          .icon{
            &.active{
              transform: rotate(-180deg);
            }
          }
        }
      }
      .symptom-answer {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        &.active {
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
          .answer {
            margin: 30px 0;
          }
        }
        .answer {
          display: flex;
          flex-direction: column;
          gap: 30px;
          color: #333333;

          h6 {
            color: inherit;
            margin-bottom: 10px;
            color: inherit;
          }
          p {
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.01em;
            color: inherit;
          }
          .body-info {
            display: flex;
            gap: 50px;
          }
          .other-symptom {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .caption {
              margin-bottom: 10px;
            }
            .title{
              margin-bottom: 0;
            }
            .symptom-list {
              .symptom-item {
                font-weight: 500;
                line-height: 22px;
                letter-spacing: -0.01em;
                color: inherit;
                .question-answer{
display: block;
                  padding-left: 27px;
                  margin-bottom: 20px;
                  position: relative;
                  &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0px;
                    width: 22px;
                    height: 22px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url(../../../img/Icons/result-check.svg);
                  }
                }
                position: relative;
                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0px;
                  width: 22px;
                  height: 22px;
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
                }
                &.select {
                  &::before {
                    background-image: url(../../../img/Icons/result-check.svg);
                  }
                }
                &.reject {
                  &::before {
                    background-image: url(../../../img/Icons/result-reject.svg);
                  }
                }
                &.skip {
                  &::before {
                    background-image: url(../../../img/Icons/result-skip.svg);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .bottom {
    margin-bottom: 30px;
    .result-container {
      .row {
        flex-direction: column;
        gap: 10px;
        .btn {
          &:last-child {
            border: 1px solid #b5b5b5;
            color: #14967f;
            background-color: transparent;
          }
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .answer-info {
    .top {
      .result-container {
        .symptom-answer {
          .answer {
            .body-info {
              gap: 25px;
            }
          }
        }
      }
    }
  }
}

.keywords-area {
  background-color: #b3cdd7;
  padding: 10px;
  margin-top: 10px;
  max-width: 335px;
  width: 100%;
  min-height: 100px;
  max-height: 240px;
  border: none;
  outline: none;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  // Scrool bar
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #757575;
  }
  &::-webkit-scrollbar-track-piece:end {
    margin-bottom: 15px;
  }
  &::-webkit-scrollbar-track-piece:start {
    margin-top: 15px;
  }
  .alert {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #8a8a8a;
  }
  .item {
    border-radius: 18px;
    background-color: #095d7e;
    padding: 9px 12px;
    height: 36px;
    display: flex;
    align-items: center;
    gap: 18px;
    .text {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.01em;
      color: #fff;
    }
    .close {
      width: 24px;
      height: 24px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 750px) {
  .keywords-area {
    max-width: 100%;
    .alert {
      font-size: 12px;
    }
  }
}
@media (max-width: 575px) {
  .keywords-area {
    gap: 5px;
    .item {
      gap: 8px;
    }
  }
}

.not-found {
  position: relative;
  width: 100%;
  height: 100vh;
  .pattern {
    position: absolute;
    background-image: url("../../img/pattern-desktop.png");
    background-repeat: repeat-x;
    width: 100%;
    height: 104px;
    &:first-child {
      top: 0;
      left: 0;
    }
    &:last-child {
      bottom: 0;
      left: 0;
    }
  }
  .container {
    padding: 104px 0;
    height: 100%;
    .row {
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 35px;
      .number {
        font-weight: 900;
        font-size: 150px;
        color: #095d7e;
      }
      .back-btn {
        display: flex;
        align-items: center;
        gap: 7px;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        color: #064259;
      }
    }
  }
}
@media (max-width: 1000px) {
  .not-found {
    .pattern {
      background-image: url("../../img/pattern-mobile.png");
      background-repeat: repeat-x;
      height: 81px;
    }
    .container {
      padding: 81px 0;
      .row {
        gap: 25px;
        .number {
          font-size: 120px;
        }
        .back-btn {
          font-size: 18px;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .not-found {
    .container {
      .row {
        gap: 20px;
        .number {
          font-size: 100px;
        }
        .back-btn {
          font-size: 16px;
        }
      }
    }
  }
}

.checkup-pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  .line {
    position: absolute;
    bottom: 70px;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(77, 57, 104, 0) 0.6%,
      #4d3968 52.18%,
      rgba(77, 57, 104, 0) 100.44%
    );
    z-index: 1;
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 575px) {
  .checkup-pagination {
    padding: 15px 20px;
  }
}

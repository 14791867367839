.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000025;
  z-index: 9999;

  .container {
    width: 100%;
    height: 100vh;
    .row {
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      .login-form-area {
        margin: 10px 0;
        max-height: 600px;
        background-color: #fff;
        padding: 65px 45px;
        border: 1px solid #6666664d;
        border-radius: 32px;
        text-align: center;
        max-width: 580px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 7px;
        &.scroll {
          &::-webkit-scrollbar-track-piece:end {
            margin-bottom: 20px;
          }

          &::-webkit-scrollbar-track-piece:start {
            margin-top: 40px;
          }
        }
        .info-group {
          max-width: 460px;
          width: 100%;
          text-align: center;
          .caption {
            font-size: 30px;
            font-weight: 500;
            line-height: 26px;
            letter-spacing: -0.01em;
            color: #333333;
          }
          .close {
            display: none;
          }
        }
        .form-group {
          width: 100%;
          .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            margin-bottom: 5px;
            .label {
              margin-bottom: 0;
            }
            .icon {
              border: none;
              outline: none;
              cursor: pointer;
              background-color: transparent;
              width: 20px;
              height: 20px;
              img {
                display: flex;
                width: 20px;
                height: 20px;
              }
            }
            .hide-area {
              cursor: pointer;
              display: flex;
            }
          }
          .label {
            font-weight: 400;
            font-size: 14px;
            line-height: 16.94px;
            letter-spacing: 0%;

            color: #666666;
            margin-bottom: 5px;
            display: block;
            width: 100%;
            text-align: left;
            &.error {
              color: #ff3a2f;
            }
          }
          .inp {
            font-weight: 400;
            font-size: 14px;
            line-height: 16.94px;
            letter-spacing: 0%;

            border: 1px solid #666666;
            outline: none;
            width: 100%;
            padding: 13px 24px;
            border-radius: 12px;
            &:focus {
              border: 1px solid #095d7e;
            }
            &.error {
              border: 1px solid #ff3a2f;
            }
          }
          .error-component {
            margin-top: 10px;
            background: #ff3a2f26;
            border-radius: 12px;
            padding: 17px;
            display: flex;
            justify-content: center;
            text-align: center;

            .info {
              display: flex;
              justify-content: center;
              align-items: center;
              max-width: 270px;
              gap: 15px;
              .icon {
                width: 20px;
                height: 20px;
                img {
                  width: 20px;
                  height: 20px;
                }
              }
              .text {
                color: #ff3a2f;
                font-weight: 400;
                font-size: 16px;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: center;
              }
            }
          }
        }
        .forget-password {
          width: 100%;
          font-weight: 400;
          font-size: 12px;
          line-height: 14.52px;
          letter-spacing: 0%;
          text-align: right;
          text-decoration: underline;
          color: #666666cc;
        }

        .submit {
          background: #14967f;
          border-radius: 28px;
          text-align: center;
          width: 100%;
          border: none;
          outline: none;
          cursor: pointer;
          color: #ffffff;
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: -1%;
          padding: 10px;
        }
        .login-adviser {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.01em;
          text-align: center;
          color: #333333;
          a {
            color: #61bc84;
            font-weight: 600;
          }
        }
        .alert-title {
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          letter-spacing: -1%;
          text-align: center;
          color: #095d7e;
        }

        .related-website {
          width: 100%;
          display: flex;
          margin-top: 7px;
          display: flex;
          gap: 10px;
          .website {
            width: 100%;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            .icon {
              max-width: 40px;
              width: 100%;
              img {
                width: 100%;
                object-fit: contain;
              }
            }
            &:first-child {
              .icon {
                max-width: 60px;
              }
            }
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -1%;
            text-align: center;
            color: #333333;
            background: #e6eff2;
            border-radius: 6px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .login-modal {
    .container {
      width: 100%;
      padding: 0;
      .row {
        height: 100vh;

        .login-form-area {
          margin: 0;
          max-height: 100%;
          padding: 40px 20px 20px;
          max-width: 100%;
          width: 100%;
          height: 100vh;
          justify-content: center;
          align-items: center;
          border-radius: 0;

          .info-group {
            max-width: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            .caption {
              font-size: 20px;
            }
            .close {
              display: inline;
              width: 30px;
              height: 30px;
              cursor: pointer;
              img {
                width: 30px;
                height: 30px;
              }
            }
          }
          .form-group {
            .top {
              margin-bottom: 2px;
            }
            .label {
              font-size: 12px;
              margin-bottom: 2px;
            }
            .inp {
              font-weight: 400;
              border: 1px solid #666666;
              padding: 10px 20px;
            }
            .error-component {
              padding: 10px;
              .info {
                max-width: 100%;
                gap: 10px;

                .text {
                  font-size: 12px;
                }
              }
            }
          }

          .login-adviser {
            font-size: 14px;
          }
          .alert-title {
            font-size: 20px;
            line-height: 25px;
          }

          .related-website {
            flex-direction: column;
            .website {
              .icon {
                max-width: 30px;
              }
              &:first-child {
                .icon {
                  max-width: 40px;
                }
              }
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 555px) {
  .login-modal {
    .container {
      width: 100%;
      padding: 0;
      .row {
        height: 100vh;

        .login-form-area {
          display: inline-block;
        }
      }
    }
  }
}

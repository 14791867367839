.feedBack {
  padding: 30px 0;
  .result-container {
    color: #333333;

    .title {
      margin-bottom: 6px;
      font-weight: 600;
      color: inherit;
    }
    .text {
      line-height: 22px;
      letter-spacing: -0.01em;
      color: inherit;
      margin-bottom: 15px;
    }
    .evaluation {
      display: flex;
      gap: 10px;
      .icon {
        padding: 2px;
        width: 26px;
        height: 26px;
        cursor: pointer;
        &.selectStar {
          svg {
            path {
              fill: #ffaa00;
            }
          }
        }
        svg {
          path {
            &.active {
              fill: #ffaa00;
            }
          }
        }
      }
    }
    .feedback-form {
      .caption {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: inherit;
        margin: 15px 0 5px;
        .alert {
          font-weight: 400;
          color: #545454;
        }
      }
      .inp {
        resize: none;
        outline: none;
        padding: 10px;
        width: 100%;
        height: 112px;
        box-shadow: 0px 0px 15px -3px #00000012;
        border: 1px solid #b5b5b5;
        border-radius: 5px;

        overflow-y: scroll;
        &::-webkit-scrollbar-track {
          background-color: transparent !important;
        }
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background-color: #757575;
        }
        &::-webkit-scrollbar-track-piece:end {
          margin-bottom: 15px;
        }
        &::-webkit-scrollbar-track-piece:start {
          margin-top: 15px;
        }
      }
      .btn {
        margin-top: 15px;
      }
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1000px) {
  .feedBack {
    padding: 23px 0;
    .result-container {
      .title {
        font-size: 14px;
      }
      .text {
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 575px) {
  .feedBack {
    padding: 15px 0;
  }
}

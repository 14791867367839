.personal-gender-select {
  padding: 50px 0 180px;
  width: 100%;
  display: flex;
  gap: 32px;
  .card {
    width: 48%;
    box-shadow: 0px 0px 10px 3px #00000012;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 32px;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      background-color: #095d7e;
      .card-image {
        svg {
          path {
            stroke: #fff;
          }
        }
      }
      .title {
        color: #fff;
      }
    }
    &.active {
      border: 3px solid #b3cdd7;
    }
    .card-image {
      width: 80px;
      aspect-ratio: 1/1;
      svg {
        width: 100%;
      }
    }
    .title {
      color: #333333;
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1000px) {
  .personal-gender-select {
    padding: 35px 0 120px;

    gap: 26px;
    .card {
      width: 48%;
      padding: 26px;
      .card-image {
        width: 65px;
      }
    }
  }
}
@media (max-width: 575px) {
  .personal-gender-select {
    padding: 20px 0 81px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    .card {
      width: 80%;
      padding: 20px;
      .card-image {
        width: 50px;
      }
      .title {
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 500px) {
  .personal-gender-select {
    .card {
      width: 100%;
    }
  }
}

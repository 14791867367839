.result-symptom {
  margin-bottom: 30px;
  padding: 30px 0;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 15px -3px #00000012;
  color: #333333;
  .result-container {
    .caption {
      font-size: 16px;
      padding-bottom: 20px;
      color: inherit;
      width: 100%;
    }
    .level-info {
      display: flex;
      gap: 25px;
      flex-wrap: wrap;
      p {
        white-space: nowrap;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #333333;
        padding-left: 20px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 4px;
          left: 0;
          width: 11px;
          height: 11px;
          border-radius: 100%;
        }
        &:first-child {
          &::after {
            background: #b00020;
          }
        }
        &:nth-child(2) {
          &::after {
            background: #ffaa00;
          }
        }
        &:last-child {
          &::after {
            background: #00B074;

          }
        }
      }
    }
    .illness {
      padding: 30px 0;
      border-bottom: 1px solid #e8e8e8;
      .degree {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        gap: 13px;
        .status {
          width: 50px;
          height: 12px;
          background: #e8e8e8;
          border-radius: 6px;
          position: relative;
          span {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            height: 100%;
            border-radius: 6px;
            background: #095d7e;
            &.low {
              background: #00B074;
              width: 40%;
            }
            &.middle {
              background: #ffaa00;
              width: 60%;
            }
            &.hiqh {
              background: #b00020;
              width: 100%;
            }
          }
        }
        .text {
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.01em;
        }
      }
      .ilness-name {
        font-size: 18px;
      }
      .advise{
        padding: 15px 0;
        max-width: 336px;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: -0.01em;
color: #8A8A8A;

      }
      .videoDoctorDirect {
        margin-bottom: 15px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        a {
          display: flex;
          gap: 5px;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.01em;
          color: #095d7e;
          .icon {
            width: 22px;
            height: 22px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
    .alert {
      margin-top: 20px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #8a8a8a;
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1000px) {
  .result-symptom {
    margin-bottom: 20px;
    padding: 23px 0;
    .result-container {
      .caption {
        padding-bottom: 15px;
      }
      .illness {
        padding: 23px 0;
        .ilness-name {
          font-size: 16px;
        }
      }
      .alert {
        font-size: 14px;
        margin-top: 15px;
      }
    }
  }
}
@media (max-width: 575px) {
  .result-symptom {
    margin-bottom: 10px;
    padding: 23px 0 8px;
    .result-container {
      .illness {
        padding: 23px 0;
        .ilness-name {
          font-size: 14px;
        }
        .videoDoctorDirect {
          margin-bottom: 15px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          a {
            font-size: 14px;
          }
        }
      }
      .alert {
        font-size: 12px;
      }
    }
  }
}

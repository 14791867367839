.seacrh-symptom {
  width: 100%;
  .title {
    margin: 10px 0 30px;
    font-weight: 500;
    color: #545454;
  }
  .seacrh-form {
    position: relative;

    .seacrh-inp {
      padding: 11px 16px;
      max-width: 335px;
      width: 100%;
      border: 1px solid #e2e8f0;
      border-radius: 10px;
      outline: none;
      border: 1px solid #095d7e;

      box-shadow: 0px 0px 0px 4px #e8edfc;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #333333;
      &::placeholder {
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #b0b0b0;
      }
    }
    .search-content {
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      // max-height: 200px;
      height: 200px;
      border: 1px solid #095d7e;
      background-color: #fff;
      border-radius: 10px;
      z-index: 10;

      // Visivle
      opacity: 0;
      visibility: hidden;
      transform: translateY(10px);
      z-index: 5;
      &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
      }

      // Scrool bar
      overflow-y: scroll;
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #757575;
      }
      &::-webkit-scrollbar-track-piece:end {
        margin-bottom: 15px;
      }
      &::-webkit-scrollbar-track-piece:start {
        margin-top: 15px;
      }
      .seacrh-item {
        &.notFound{
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
        }
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.01em;

        padding: 10px 15px;
        background-color: #fff;
        cursor: pointer;

        &:first-child {
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }
        &:last-child {
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        &:hover {
          background-color: #e8e8e8;
        }
      }
    }
  }
  .search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: -1;
    &.active {
      z-index: 2;
    }
  }
}
/* -------------------------------- Animation ------------------------------- */
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 750px) {
  .seacrh-symptom {
    width: 100%;
    .title {
      font-size: 14px;
    }
    .seacrh-form {
      .seacrh-inp {
        max-width: 100%;
      }
      .search-content {
        max-width: 100%;
      }
    }
  }
}
@media (max-width: 575px) {
  .seacrh-symptom {
    .title {
      font-size: 12px;
      margin: 15px 0;
    }
    .seacrh-form {
      .seacrh-inp {
        padding: 8px 10px;
        font-size: 14px;
        &::placeholder {
          font-size: 14px;
        }
      }
    }
  }
}

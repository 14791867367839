.side-bar {
  max-width: 380px;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease-in;
  &.isOpenMenu {
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
  }
  .top {
    padding: 13px 20px;
    background: #14967f;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .body-part {
      font-weight: 700;
      color: #fff;
    }
    .close-icon {
      width: 30px;
      height: 30px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .bottom {
    background-color: #fff;
    height: 100%;
    // Scrool bar
    overflow-y: scroll;
    &::-webkit-scrollbar-track {
      background-color: transparent !important;
    }
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #757575;
    }
    &::-webkit-scrollbar-track-piece:end {
      margin-bottom: 15px;
    }
    &::-webkit-scrollbar-track-piece:start {
      margin-top: 15px;
    }
    .symptom-list {
      .symptom-item {
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #333333;
        padding: 19px 20px;
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: space-between;
        span {
          max-width: 310px;
          width: 100%;
          word-wrap: break-word;
        }
        .tick {
          width: 15px;
          height: 11px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        // &:last-child {
        //   padding-bottom: 40px;
        // }
        &:hover {
          background-color: #eaeaea;
        }
      }
    }
  }
}

.header {
  width: 100%;
  background-color: #fff;
  min-height: 80px;
  transition: transform 1s linear;
  z-index: 9999;
  &.checkup-header {
    background-color: #095d7e;
    color: #fff;
    min-height: 50px;
    .container {
      .row {
        height: 50px !important;
        min-height: 50px;
        .logo {
          height: 100%;
          img {
            height: 100%;
          }
        }
      }
    }
  }
  &.nonVisible {
    display: none;
  }

  .container {
    height: 100%;
    .row {
      min-height: 80px;
      justify-content: space-between;
      align-items: center;
      .logo {
        img {
          width: max-content;
          object-fit: contain;
        }
      }
      .additional-area {
        display: flex;
        gap: 10px;
        align-items: center;
        .lang-area {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          position: relative;
          z-index:9999;
          width: 120px;
          .lang-text{
            text-transform: capitalize;
          }
          .lang-list {
            position: absolute;
            top: 27px;
            right: -7px;
            border-radius: 10px;
            border: 1px solid #095d7e;
            background-color: #fff;
            overflow: hidden;
            opacity: 0;
            visibility: hidden;
            transform: translateY(10px);
            transition: 0.5s ease;
            &.active {
              opacity: 1;
              visibility: visible;
              transform: translateY(0px);
            }
            .lang-item {
              font-weight: 500;
              line-height: 22px;
              padding: 11px 31px;
              color: #333333;
              transition: 0.5s ease;
  
              &:hover {
                background: #f4f4f4;
              }
            }
          }
        }
        .btn {
          opacity: 0;
          visibility: hidden;
          transition: 0.5s ease;
          &.active {
            opacity: 1;
            visibility: visible;
          }
        }
        .profile-icon{
          width: 34px;
          height: 34px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  // .overlay {
  //   display: none;
  //   top: 0;
  //   left: 0;
  //   width: 100vh;
  //   height: 100vh;
  //   background-color: transparent;
  //   &.active {
  //     display: block;
  //     position: fixed;
  //   }
  // }
}

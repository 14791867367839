.impressions {
  padding: 120px 0;
  .feedback-area {
    margin-top: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 4%;
    .feedback-card {
      width: 22%;
      .top {
        max-width: 159px;
        margin: 0 auto;
        padding: 30px 0;
        text-align: center;
        .personal-name {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: -0.01em;
          color: #053345;
          margin-bottom: 10px;
        }
        .star-area {
          display: flex;
          justify-content: center;
          gap: 5px;
          .star {
            &.active {
              svg {
                path {
                  fill: #ffaa00;
                }
              }
            }
          }
        }
        .comment {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.01em;
          color: #545454;
          margin-top: 15px;
        }
      }
      &.feedback-card-loader{
.top{
  .personal-name{
    max-width: 200px;
    width: 100%;
    height: 30px;
    background: #eee;
    background: linear-gradient(
      110deg,
      #ececec 8%,
      #f5f5f5 18%,
      #ececec 33%
    );
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
  .comment{
    max-width: 400px;
    width: 100%;
    height: 100px;
    background: #eee;
    background: linear-gradient(
      110deg,
      #ececec 8%,
      #f5f5f5 18%,
      #ececec 33%
    );
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
  .personal-name{
    max-width: 200px;
    width: 100%;
    height: 30px;
    background: #eee;
    background: linear-gradient(
      110deg,
      #ececec 8%,
      #f5f5f5 18%,
      #ececec 33%
    );
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
}
      }
      
    }
  }
  .pagination {
    display: flex;
    width: 100%;
    margin: 25px 0;
    justify-content: center;
    align-items: center;
    .prev,
    .next {
      background: #095d7e;
      padding: 10px 16px;
      border: none;
      outline: none;
      cursor: pointer;
      box-shadow: 0px 1px 2px 0px #0f172a0f;

      img {
        width: 24px;
        height: 24px;
      }
    }
    .info-page {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -1%;
      white-space: nowrap;
      color: #202a2b;
      padding: 12px 16px;
      border-top: 1px solid #e2e8f0;
      border-bottom: 1px solid #e2e8f0;
      box-shadow: 0px 1px 2px 0px #0f172a0f;
    }
    .prev {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .next {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1000px) {
  .impressions {
    padding: 70px 0;
    .feedback-area {
      margin-top: 40px;
      flex-wrap: wrap;
      row-gap: 20px;
      .feedback-card {
        width: 42%;
        .top {
          padding: 25px 0;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .impressions {
    padding: 15px 0;
    .feedback-area {
      margin-top: 15px;
      .feedback-card {
        width: 100%;
        .top {
          padding: 20px 0;
        }
      }
    }
  }
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.modal {
  .container {
    .row {
      align-items: flex-start;
      gap: 24px;
      .video {
        video {
          border-radius: 14px;
          max-width: 600px;
          //   width: 600px;
          width: 100%;
        }
      }
      .close {
        background-color: #fff;
        padding: 4px 12px 4px 4px;
        border-radius: 16px;
        color: black;
        width: 82px;
        height: 32px;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
        span {
          font-size: 12px;
          line-height: 12px;
          letter-spacing: 0em;
        }
      }
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1000px) {
  .modal {
    .container {
      .row {
        flex-direction: column-reverse;
        .video {
          video {
            max-width: 100%;
          }
        }
        .close {
          align-self: flex-end;
        }
      }
    }
  }
}

.result-general-info {
  background-color: #fff;
  box-shadow: 0px 0px 15px -3px #00000012;
  border-radius: 15px;
  margin-bottom: 30px;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 79px;
    background-color: #0095ff;
  }
  .result-container {
    color: #333333;
    padding-right: 79px;
    .title {
      font-size: 30px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: -0.01em;
      margin-bottom: 20px;
      color: inherit;
    }
    .text {
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: inherit;
      &.primary {
        margin-bottom: 22px;
      }
      &.secondary {
        margin-bottom: 15px;
      }
    }
    .videodoktor-directory {
      font-family: Inter;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -1%;
      color: #ffffff;
      padding: 10px 29px;
      display: flex;
      align-items: center;
      gap: 11px;
      background: #14967f;
      border-radius: 20px;
      width: max-content;
      .icon {
        width: 24px;
        height: 24px;
        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
      }
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1000px) {
  .result-general-info {
    margin-bottom: 20px;
    padding: 33px 0;
    &::after {
      width: 55px;
    }
    .result-container {
      padding-right: 55px;
      .title {
        font-size: 25px;
        line-height: 25px;
      }
      .text {
        font-size: 14px;
        line-height: 18px;
      }
      .videodoktor-directory {
        font-size: 12px;
        line-height: 15.71px;
        padding: 10px;
        .icon {
          width: 17px;
          height: 17px;
          img {
            width: 17px;
            height: 17px;
          }
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .result-general-info {
    margin-bottom: 10px;
    padding: 15px 0;
    &::after {
      width: 30px;
    }
    .result-container {
      padding-right: 40px;
      .title {
        font-size: 18px;
      }
      .text {
        font-size: 12px;
        margin-bottom: 20px;
      }
    }
  }
}
// @media (max-width: 400px) {
//   .result-general-info {
//     .result-container {

//     }
//   }
// }

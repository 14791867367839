.profile{
    min-height: calc(100vh - 254px);
    padding: 23px 0;
    .container{
        .row{
            gap: 32px;
        }
    }
}
@media(max-width:768px){
    .profile{
        min-height:100%;
        padding: 23px 0;
        .container{
            .row{
                flex-direction: column;
                gap: 10px;
            }
        }
    } 
}
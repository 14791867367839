.profile-sidebar {
  width: 270px;
  .mobile-caption {
    display: none;
    align-items: center;
    gap: 10px;
  }
  .profile-list {
    width: 100%;
    .profile-item {
      width: 100%;
      padding: 15px 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #0f172a;
      transition: 0.3s ease;
      margin-bottom: 8px;
      border-radius: 16px;
      cursor: pointer;
      &.active {
        background: #14967f;
        box-shadow: 0px 5px 25px -5px #0c0b0d0a;

        color: #ffffff;
      }
      span {
        width: 24px;
        height: 24px;
        img {
          width: 24px;
          height: 24px;
        }
      }
      &.logout {
        border-top: 3px solid #f5f5f5;
        border-radius: 0;
      }
    }
  }
}

@media (max-width: 1024px) {
  .profile-sidebar {
    width: 250px;
    .profile-list {
      width: 100%;
      .profile-item {
        width: 100%;
        padding: 12px 14px;
        font-size: 14px;
        transition: 0.3s ease;
        margin-bottom: 6px;
        span {
          width: 22px;
          height: 22px;
          img {
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .profile-sidebar {
    width: 100%;
    .mobile-caption {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }
    .profile-list {
      width: 85%;
      height: 0;
      opacity: 0;
      visibility: hidden;
      z-index: -2;
      top: 130px;
      position: absolute;
      background-color: #fff;
      transition: .3s ease;
      &.active{
        opacity: 1;
        height: 140px;
        visibility: visible;
         z-index: 999;
         top: 140px;
      }
      .profile-item {
        width: 100%;
        padding: 6px 10px;
        transition: 0.3s ease;
        margin-bottom: 6px;

        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -1%;
        border-radius: 10px;
        box-shadow: 0px 1px 3px 1px #00000026;
        box-shadow: 0px 1px 2px 0px #0000004d;
        &.logout {
          border-top: 3px solid #f5f5f5;
          border: none;
          border-radius: 10px !important;
        }
        span {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 428px) {
  .profile-sidebar {
    .profile-list {
      width: 90%;
    }
  }
}

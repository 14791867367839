.body-info {
  width: 100%;
  .row {
    padding: 15px 0;
    width: 100%;
    justify-content: space-between;
    align-content: center;

    .decrement,
    .increment {
      border: none;
      outline: none;
      border-radius: 100%;
      width: 50px;
      height: 50px;
      background-color: #fff;
      cursor: pointer;
      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .slider {
      max-width: 1000px;
      width: 100%;
      height: 6px;
      margin: 20px 30px 0 30px;
      .horizontal-slider {
        display: flex;
        align-items: center;
        height: 6px;

        .example-track {
          height: 100%;
        }
        .example-track-0 {
          background: #095d7e;
          border-radius: 3px;
        }
        .example-track-1 {
          background: #8eb4c4;
          border-radius: 3px;
        }
        .example-thumb {
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          letter-spacing: -0.01em;
          color: #fff;
          border-radius: none;
          outline: none;
          padding: 6px 27px;
          background-color: #14967f;
          border-radius: 22px;
          cursor: pointer;
          &::before {
            position: absolute;
            content: "";
            background-image: url(../../../img/Icons/left-drop-desktop.svg);
            width: 12px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: 20px;
            top: 12px;
            left: 10px;
          }
          &::after {
            position: absolute;
            content: "";
            background-image: url(../../../img/Icons/right-drop-desktop.svg);
            width: 12px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: 20px;
            top: 12px;
            right: 10px;
          }
        }
      }
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1000px) {
  .body-info {
    .row {
      .decrement,
      .increment {
        width: 45px;
        height: 45px;
      }
      .slider {
        height: 4px;
        margin: 20px 30px 0 30px;
        .horizontal-slider {
          height: 4px;
          .example-thumb {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.01em;
            padding: 5px 22px;
            border-radius: 19px;
            &::before {
              background-image: url(../../../img/Icons/left-drop-mobile.svg);
              width: 5px;
              height: 11px;
              top: 9px;
              left: 8px;
            }
            &::after {
              background-image: url(../../../img/Icons/right-drop-mobile.svg);
              width: 5px;
              height: 11px;
              top: 9px;
              right: 8px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .body-info {
    .row {
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
      .decrement,
      .increment {
        width: 40px;
        height: 40px;
      }
      .slider {
        order: -1;
        height: 2px;
        margin: 20px 30px 0 30px;
        .horizontal-slider {
          height: 2px;
          .example-thumb {
            padding: 5px 17px;
            border-radius: 17px;
          }
        }
      }
    }
  }
}

.checkup-caption {
  width: 100%;
  padding-top: 27px;
  position: absolute;
  top: 0;
  left: 0;
  &.static{
    position: static;
  }
}
@media (max-width: 1000px) {
  .checkup-caption {
    padding-top: 21px;
  }
}
@media (max-width: 575px) {
  .checkup-caption {
    position: relative;
    padding: 15px 0 0 0 ;
  }
}

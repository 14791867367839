.medCheckup-info {
  padding: 100px 0;
  .container {
    .row {
      gap: 32px;
      .home-caption {
        max-width: 521px;
        width: 100%;
        .detail {
          max-width: 311px;
        }
      }
      .info {
        max-width: 743px;
        width: 100%;
        gap: 16px;
        display: flex;
        align-items: center;
        .home-card {
          max-width: 237px;
          &:first-child {
            margin-bottom: -100px;
          }
          &:last-child {
            margin-top: -100px;
          }
          .top {
            border-radius: 10px;
          }
        }
      }
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1200px) {
  .medCheckup-info {
    .container {
      .row {
        gap: 10px;
        .info {
          .home-card {
            padding: 20px 25px;
          }
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .medCheckup-info {
    padding: 50px 0;
    .container {
      .row {
        gap: 30px;
        flex-direction: column;
        .info {
          max-width: 100%;
          align-items: center;
          justify-content: space-between;
          .home-card {
            max-width: none;
            width: 33%;
            &:first-child {
              margin-bottom: 0px;
            }
            &:last-child {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .medCheckup-info {
    padding: 15px 0;
    .container {
      .row {
        .info {
          flex-direction: column;
          align-items: center;
          gap: 20px;
          .home-card {
            width: 100%;
          }
        }
      }
    }
  }
}

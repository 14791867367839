.seacrh-loading-item {
    padding: 4px 2px;
    background-color: #fff;
    &:first-child {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .text {
      display: inline-block;
      width: 100%;
      width: 100%;
      height: 28px;
      background-color: #eee;
      background: linear-gradient(
        110deg,
        #ececec 8%,
        #f5f5f5 18%,
        #ececec 33%
      );
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }
  }
.profile-history {
  width: calc(100% - 300px);
  padding: 20px 24px;
  box-shadow: 0px 0px 4px -2px #0f172a82;
  min-height: calc(100vh - 300px);
  box-shadow: 0px 0px 9.9px -1px #0f172a1a;
  border-radius: 16px;
  .history-list {
    .loader-line {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      margin-bottom: 10px;
      box-shadow: 0px 1px 3px 1px #00000026;
      box-shadow: 0px 1px 2px 0px #0000004d;
      padding: 10px 25px;
      border-radius: 6px;
      .left-side {
        max-width: 200px;
        width: 100%;
        height: 40px;
        background: #eee;
        background: linear-gradient(
          110deg,
          #ececec 8%,
          #f5f5f5 18%,
          #ececec 33%
        );
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
      }
      .right-side {
        max-width: 100px;
        width: 100%;
        height: 30px;
        background: #eee;
        background: linear-gradient(
          110deg,
          #ececec 8%,
          #f5f5f5 18%,
          #ececec 33%
        );
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
      }
    }
    .history-item {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 10px;
      box-shadow: 0px 1px 3px 1px #00000026;
      box-shadow: 0px 1px 2px 0px #0000004d;
      padding: 10px 25px;
      border-radius: 6px;
      .left-side {
        color: #333333;

        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -1%;
          color: inherit;
        }
        .subTitle {
          font-weight: 600;
          font-size: 10px;
          line-height: 15px;
          letter-spacing: -1%;
          color: inherit;
        }
        .date {
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          letter-spacing: -1%;
          color: inherit;
        }
      }
      button {
        border: none;
        outline: none;
        cursor: pointer;
        background-color: transparent;
        display: flex;
        gap: 5px;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #095d7e;
        .icon {
          width: 22px;
          height: 22px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .empty {
      width: 100%;
      min-height: calc(100vh - 341px);
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -1%;
        text-align: center;
        padding: 10px;
        text-align: center;
      }
    }
  }
  .pagination {
    display: flex;
    width: 100%;
    margin: 25px 0;
    justify-content: center;
    align-items: center;
    .prev,
    .next {
      background: #095d7e;
      padding: 10px 16px;
      border: none;
      outline: none;
      cursor: pointer;
      box-shadow: 0px 1px 2px 0px #0f172a0f;

      img {
        width: 24px;
        height: 24px;
      }
    }
    .info-page {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -1%;
      white-space: nowrap;
      color: #202a2b;
      padding: 12px 16px;
      border-top: 1px solid #e2e8f0;
      border-bottom: 1px solid #e2e8f0;
      box-shadow: 0px 1px 2px 0px #0f172a0f;
    }
    .prev {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .next {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
@media (max-width: 1024px) {
  .profile-history {
    width: calc(100% - 250px);
  }
}

@media (max-width: 768px) {
  .profile-history {
    width: 100%;
  }
}
@media (max-width: 428px) {
  .profile-history {
    .history-list {
      .history-item {
        .left-side {
          .title {
            font-size: 14px;
          }
        }
        button {
          font-size: 14px;
          .icon {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.checkup-area {
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 15px -3px #00000012;
  .checkup-container {
    padding: 53px 0 70px;
    margin: 0 auto;
    width: 82.87%;
    position: relative;
    &.deActiveQuestion {
      padding-top: 0;
    }
  }
}
@media (max-width: 1000px) {
  .checkup-area {
    .checkup-container {
      padding: 53px 0 70px;
      width: 90%;
      min-height: 90vh;
    }
  }
}
@media (max-width: 575px) {
  .checkup-area {
    .checkup-container {
      width: 100%;
      padding: 0 20px;
      min-height: 70vh;
  
    }
  }
}

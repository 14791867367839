.body {
  position: relative;
  //   transform: scale(1.4);
  .bg-body {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    svg {
      path {
        fill: transparent;
        &:hover {
          fill: #b3cdd7;
          cursor: pointer;
        }
      }
    }
  }
  .woman-front {
    left: 1px;
  }
}

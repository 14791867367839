.dynamic-symptom {
  min-height: 430px;
  .dynamic-form {
    .form-group {
      display: flex;
      gap: 30px;
      align-items: center;
      padding: 10px 27px;
      border-bottom: 1px solid #b0b0b0;
      &:last-child {
        border-color: transparent;
      }
    }
  }
  .error {
    .text {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.01em;
      color: #b00020;
      margin: 6px 0;
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1000px) {
  .dynamic-symptom {
    min-height: 100%;
    .dynamic-form {
      .form-group {
        gap: 23px;
        padding: 10px 27px;
      }
    }
  }
}
@media (max-width: 575px) {
  .dynamic-symptom {
    min-height: 100%;
    padding: 15px 0 81px;
    .dynamic-form {
      .form-group {
        gap: 15px;
        padding: 10px 15px;
      }
    }
  }
}

.progress-bar {
  .radialProgressBar {
    display: inline-block;
    min-width: 168px;
    height: 48px;
    padding: 4px;
    border-radius: 100px;
    .info-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      background-color: #fff;
      border-radius: 44px;
      padding: 4px;
      .text {
        display: inline-block;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: -0.01em;
        padding: 0 20px;
        text-align: center;
        color: #333333;
        z-index: 1;
      }
      .circle {
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.01em;

        background-color: #e8e8e8;
        border-radius: 50%;
        color: #4d3968;
        z-index: 1;
      }
    }
  }
}

.progress-1 {
  background-image: linear-gradient(20deg, #d9d9d9 50%, transparent 60%),
    linear-gradient(90deg, #14967f 50%, #d9d9d9 60%);
}

.progress-2 {
  background-image: linear-gradient(-20deg, #d9d9d9 50%, transparent 60%),
    linear-gradient(90deg, #14967f 50%, #d9d9d9 60%);
}

.progress-3 {
  background-image: linear-gradient(-29deg, #d9d9d9 50%, transparent 60%),
    linear-gradient(90deg, #14967f 50%, #d9d9d9 60%);
}

.progress-4 {
  background-image: linear-gradient(90deg, #14967f 50%, transparent 60%),
    linear-gradient(20deg, #14967f 50%, #d9d9d9 60%);
}
.progress-5 {
  background-image: linear-gradient(90deg, #14967f 50%, transparent 60%),
    linear-gradient(-20deg, #14967f 50%, #d9d9d9 60%);
}
.progress-6 {
  background-image: linear-gradient(90deg, #14967f 50%, transparent 60%),
    linear-gradient(-90deg, #14967f 50%, #d9d9d9 60%);
}

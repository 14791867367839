.check-up {
  width: 100%;
  background-color: #e6eff2;
  padding-bottom: 16px;
  .container {
    .progress-bar {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 10px 0;
    }
  }
}

.reference {
  .container {
    padding-top: 120px;
    padding-bottom: 120px;
    background: #e6eff2;
  }
  .head {
    .home-caption {
      .detail {
        max-width: 418px;
      }
    }
    margin-bottom: 70px;
  }
  .body {
    .row {
      justify-content: space-between;
      .home-card {
        width: 30%;
        .top {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1000px) {
  .reference {
    .container {
      padding-top: 70px;
      padding-bottom: 70px;
    }
    .head {
      margin-bottom: 55px;
    }
    .body {
      .row {
        .home-card {
          width: 31%;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .reference {
    .container {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .head {
      margin-bottom: 47px;
    }
    .body {
      .row {
        flex-direction: column;
        gap: 10px;
        .home-card {
          width: 100%;
        }
      }
    }
  }
}

.footer {
  width: 100%;
  margin-bottom: 35px;
  .pattern {
    background-image: url("../../img/pattern-desktop.png");
    background-repeat: repeat-x;
    height: 104px;
    margin-bottom: 35px;
  }
  .container {
    .row {
      justify-content: space-between;
      align-items: center;
      .social-list {
        display: flex;
        gap: 14px;
        .social-item {
          a {
            padding: 5px;
            .image {
              width: 24px;
              height: 24px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
      .last-text {
        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.01em;
          color: #8a8a8a;
        }
      }
      .lang-area {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        position: relative;
        z-index: 1;
        width: 120px;
        .lang-list {
          position: absolute;
          bottom: 27px;
          right: -7px;
          border-radius: 10px;
          border: 1px solid #095d7e;
          background-color: #fff;
          overflow: hidden;
          opacity: 0;
          visibility: hidden;
          transform: translateY(10px);
          transition: 0.5s ease;
          &.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0px);
          }
          .lang-item {
            font-weight: 500;
            line-height: 22px;
            padding: 11px 31px;
            color: #333333;
            transition: 0.5s ease;

            &:hover {
              background: #f4f4f4;
            }
          }
        }
      }
    }
  }
  // .overlay {
  //   display: none;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: transparent;
  //   &.active {
  //     display: block;
  //     position: fixed;
  //   }
  // }
}
/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1000px) {
  .footer {
    margin-bottom: 30px;
    .pattern {
      background-image: url("../../img/pattern-mobile.png");
      background-repeat: repeat-x;
      height: 81px;
      margin-bottom: 30px;
    }
    .container {
      padding: 0 35px;
    }
  }
}
@media (max-width: 575px) {
  .footer {
    margin-bottom: 32px;
    .pattern {
      margin-bottom: 32px;
    }
    .container {
      padding: 0 35px;
      .row {
        align-items: center;
        flex-direction: column;
        gap: 24px;
        .last-text {
          p {
            text-align: center;
          }
        }
      }
    }
  }
}
